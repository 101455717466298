import {BryntumScheduler} from "@bryntum/scheduler-react"
import {DateHelper, ObjectHelper} from "@bryntum/scheduler/scheduler.umd"
import React, {useCallback, useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import "../App.css"
import EditNotePopup from "../components/EditNotePopup"
import ReleaseCreatePopup from "../components/ReleaseCreatePopup"
import ReleaseEditPopup from "../components/ReleaseEditPopup"

import {EVENT_TYPE, RESOURCES, SELECTED_GAME} from "../constants"
import {pushEventChangesInAsana, sendAddEvent, sendRemoveEvent, sendUpdateEvent} from "../requests"
import {hasText, isExists, setFeaturingType, setHoursForEventDates} from "../utils"
import manageCalendar from "./manageCalendar";
import DeletePopup from "../components/DeletePopup";
import Popup from "../elements/Popup";
import {eventClick} from "./bryntum_configs/eventClick";
import {
    clearCopiedFieldForEvents, copyHours,
    hideEventTimeRange,
    processTimeAxisHeaderClick, replaceOrAppendDate,
    setEventTimeRange
} from "./bryntum_helpers";
import {isHidePushCompositeEventButton} from "./bryntum_configs/compositeEventUtils";
import {fetchLabelsConfig} from "../redux/labels-config/actions";


const RELEASES = {
    "DEV версия": ['BC апрув', 'RC апрув', 'Feature freeze', 'HardUpdate'],
    "GP Сабмит/Релиз": ['Release: Google Play', 'Submit: Google Play'],
    "iOS Сабмит/Релиз": ['Release: iOS', 'Submit: iOS'],
    "AM Сабмит/Релиз": ['Release: Amazon', 'Submit: Amazon'],
    "WS Сабмит/Релиз": ["Submit: Windows Store", "Release: Windows Store"],
    "Huawei Сабмит/Релиз": ['Release: Huawei', 'Submit: Huawei'],
    "FB Сабмит/Релиз": ['Submit: Facebook', 'Release: Facebook'],
    "WebGL Сабмит/Релиз": ['Release: WebGL'],
    "MS Start Сабмит/Релиз": ['Release: MS', "Submit: MS"],
};

const CMCalendar = (props) => {
    let [existsInAsana, setExistsInAsana] = useState(false);
    const [deleteRecords, setDeleteRecords] = useState([]);
    const user = useSelector((state) => state.user.currentUser);
    let timeInStartDate = 0;
    let timeInEndDate = 0;
    const labelsConfig = useSelector((state) => state.labelsConfig);

    const dispatch = useDispatch()



    useEffect(() => {
        dispatch(fetchLabelsConfig("ba"))
    }, [])
    const getEditorConfigs = () => {
        return {
            multiEventSelect: true,
            eventDragFeature: {
                constrainDragToResource: true
            },
            rowCopyPasteFeature: false,
            eventResizeFeature: true,
            eventMenuFeature: {
                items: {
                    editEventNote: {
                        text: 'Edit event note',
                        icon: "b-fa b-fa-fw b-fa-sticky-note",
                        onItem({eventRecord}) {
                            props.showNoteEditor(eventRecord)
                        }
                    },
                    eventLink: {
                        text: 'Link to Asana',
                        icon: "b-fa b-fa-fw b-fa-external-link-alt",
                        weight: 0,
                        onItem({eventRecord}) {
                            if (hasText(eventRecord.link)) {
                                window.open(eventRecord.link, "_blank")
                            }
                        }
                    },
                    copyLink: {
                        text: 'Copy link',
                        icon: "b-fa b-fa-fw b-icon-copy",
                        weight: 0,
                        onItem({eventRecord}) {
                            if (hasText(eventRecord.link)) {
                                navigator.clipboard.writeText(eventRecord.link).then();
                            }
                        }
                    },
                    pushToAsana: {
                        text: 'Push to Asana',
                        icon: "b-fa b-fa-fw b-fa-angle-right",
                        onItem({eventRecord}) {
                            pushEventChangesInAsana(eventRecord, SELECTED_GAME.CM.toLowerCase())
                        }
                    },
                    manageFeaturing: {
                        text: 'Add to featuring',
                        icon: "b-fa b-fa-fw b-icon-copy",
                        onItem(item) {
                            let clonedEvent = item.eventRecord.data;
                            clonedEvent.addFeaturing = true;
                            // clonedEvent.startDate = clonedEvent.startDate.toString()
                            sendUpdateEvent(clonedEvent, SELECTED_GAME.CM.toLowerCase());
                        }
                    },
                    managePromo: {
                        text: 'Add to promo',
                        type: "checkbox",
                        checked: false,
                        icon: "b-fa b-fa-fw",
                        onAction(item) {
                            if (item.checked) {
                                let currentRecord = item.source.eventRecord;
                                let promoResource = props.scheduler.current.schedulerInstance.resourceStore.findRecord("name", "LiveOps Promo");
                                let clonedCurrentRecord = ObjectHelper.clone(currentRecord.data);
                                clonedCurrentRecord.id = clonedCurrentRecord.id + "_promo";
                                clonedCurrentRecord.resourceId = promoResource.id;
                                clonedCurrentRecord.type = EVENT_TYPE.PROMO;
                                clonedCurrentRecord.resizable = false;
                                props.scheduler.current.schedulerInstance.eventStore.addAsync(clonedCurrentRecord);
                                currentRecord.setAsync("promo", true)
                                sendAddEvent(clonedCurrentRecord, SELECTED_GAME.CM.toLowerCase());
                            } else {
                                let currentRecord = item.source.eventRecord;
                                let promoRecord = props.scheduler.current.schedulerInstance.eventStore.findRecord("id", currentRecord.id + "_promo");
                                currentRecord.setAsync("promo", false);

                                props.scheduler.current.schedulerInstance.eventStore.remove(promoRecord.id);
                                let clonedCurrentRecord = ObjectHelper.clone(promoRecord);
                                clonedCurrentRecord.id = currentRecord.id;
                                sendRemoveEvent(promoRecord, SELECTED_GAME.CM.toLowerCase())
                            }
                        }
                    },
                    manageAutorun: {
                        text: 'Autorun',
                        type: "checkbox",
                        checked: false,
                        icon: "b-fa b-fa-fw",
                        onAction(item) {
                            if (item.checked) {
                                let currentRecord = item.source.eventRecord;
                                currentRecord.autorun = true;
                                sendUpdateEvent(currentRecord, SELECTED_GAME.CM.toLowerCase());
                            } else {
                                let currentRecord = item.source.eventRecord;
                                currentRecord.autorun = false;
                                sendUpdateEvent(currentRecord, SELECTED_GAME.CM.toLowerCase());
                            }
                        }
                    },
                    manageBevbuild: {
                        text: 'Devbuild',
                        type: "checkbox",
                        checked: false,
                        icon: "b-fa b-fa-fw",
                        onAction(item) {
                            if (item.checked) {
                                let currentRecord = item.source.eventRecord;
                                currentRecord.devbuild = true;
                                currentRecord.mongoStatus = "UPDATED";
                                sendUpdateEvent(currentRecord, SELECTED_GAME.CM.toLowerCase());
                            } else {
                                let currentRecord = item.source.eventRecord;
                                currentRecord.devbuild = false;
                                currentRecord.mongoStatus = "UPDATED";
                                sendUpdateEvent(currentRecord, SELECTED_GAME.CM.toLowerCase());
                            }
                        }
                    },
                    cutEvent: false
                },
                processItems({eventRecord, items}) {
                    items.managePromo.eventRecord = eventRecord
                    items.manageAutorun.eventRecord = eventRecord
                    items.manageBevbuild.eventRecord = eventRecord
                    items.manageFeaturing.eventRecord = eventRecord

                    function checkPromoRecord() {
                        if (eventRecord.promo) {
                            items.managePromo.checked = true;
                        }
                    }
                    function checkAutorunRecord() {
                        if (eventRecord.autorun) {
                            items.manageAutorun.checked = true;
                        }
                    }
                    function checkDevbuildRecord() {
                        if (eventRecord.devbuild) {
                            items.manageBevbuild.checked = true;
                        }
                    }
                    function checkFeaturingRecord() {
                        if (eventRecord && eventRecord.featuring) {
                            items.manageFeaturing.checked = true;
                        }
                    }

                    checkPromoRecord();
                    checkAutorunRecord();
                    checkDevbuildRecord();
                    // checkFeaturingRecord();
                    if (eventRecord.isArchive) {
                        items.editEventNote.disabled = true;
                        items.editEvent.disabled = true;
                        items.deleteEvent.disabled = true;
                        items.copyEvent.disabled = true;
                        items.managePromo.disabled = true;
                        items.manageFeaturing.disabled = true;
                        items.manageAutorun.disabled = true;
                        items.manageBevbuild.hidden = true;
                    } else if (eventRecord.type === EVENT_TYPE.HOLIDAY) {
                        items.editEventNote.hidden = true;
                        items.managePromo.hidden = true;
                        items.manageBevbuild.hidden = true;
                        // items.manageFeaturing.hidden = true;
                    } else if (eventRecord.type === EVENT_TYPE.RELEASE) {
                        items.copyEvent.hidden = true;
                        //items.managePromo.hidden = true;
                        items.manageFeaturing.hidden = true;
                        items.manageAutorun.hidden = true;
                        items.manageBevbuild.hidden = true;
                        if (isExists(eventRecord.parentRelease)) {
                            items.managePromo.hidden = true;
                            items.manageFeaturing.hidden = true;
                            items.manageAutorun.hidden = true;
                        }
                    } else if (eventRecord.type === EVENT_TYPE.ASO) {
                        items.copyEvent.hidden = true;
                        items.managePromo.hidden = true;
                        items.manageFeaturing.hidden = true;
                        items.manageBevbuild.hidden = true;
                        items.managePromo.hidden = true;

                    } else if (eventRecord.type === EVENT_TYPE.PROMO) {

                        items.editEventNote.hidden = true;
                        items.editEvent.hidden = true;
                        items.deleteEvent.hidden = true;
                        items.copyEvent.hidden = true;
                        items.manageFeaturing.hidden = true;
                        items.managePromo.hidden = true;
                        items.manageBevbuild.hidden = true;
                        items.manageAutorun.hidden = true;


                    } else if (eventRecord.type === EVENT_TYPE.COMMUNITY) {
                        items.managePromo.hidden = true;
                        items.manageFeaturing.hidden = true;
                        items.manageBevbuild.hidden = true;
                    } else if (eventRecord.type ===EVENT_TYPE.EVENT && window.atob(eventRecord.resourceId) === 'Featuring') {
                        items.managePromo.hidden = true;
                        items.manageFeaturing.hidden = true;
                        items.editEventNote.hidden = true;
                        // items.editEvent.hidden = true;
                        // items.deleteEvent.hidden = true;
                        items.copyEvent.hidden = true;
                        items.pushToAsana.hidden = true;
                    }
                    else if (eventRecord.type === EVENT_TYPE.FEATURING) {
                        items.managePromo.hidden = true;
                        items.manageFeaturing.hidden = true;
                        items.editEventNote.hidden = true;
                        items.copyLink.hidden = true;
                        // items.editEvent.hidden = true;
                        // items.deleteEvent.hidden = true;
                        items.copyEvent.hidden = true;
                        items.pushToAsana.hidden = true;
                        items.manageBevbuild.hidden = true;
                    } else if (eventRecord.type === EVENT_TYPE.BRANDFORMANCE) {
                        items.managePromo.hidden = true;
                        items.manageFeaturing.hidden = true;
                        items.editEventNote.hidden = true;
                        items.copyEvent.hidden = true;
                        items.pushToAsana.hidden = true;
                        items.manageBevbuild.hidden = true;
                    } else if (eventRecord.type === EVENT_TYPE.IMPORTANT_DATES) {
                        items.managePromo.hidden = true;
                        items.manageFeaturing.hidden = true;
                        items.manageBevbuild.hidden = true;
                    }else if (eventRecord.type === EVENT_TYPE.JAPANESE_HOLIDAYS) {
                        items.managePromo.hidden = true;
                        // items.manageFeaturing.hidden = true;
                        items.editEventNote.hidden = true;
                        items.eventLink.disabled = true;
                        items.manageBevbuild.hidden = true;
                    } else if (eventRecord.type === EVENT_TYPE.AB_TEST) {
                        items.manageAutorun.hidden = true;
                    }
                    if (!isExists(eventRecord.link)) {
                        items.eventLink.disabled = true;
                    }
                    props.setStatusForPushToAsanaEventContextMenu(eventRecord, items)
                }
            },
            listeners: {
                beforeEventDelete: (source) => processBeforeEventDelete(source),
                beforeEventEdit: (source) => {
                    console.log('beforeEventEdit')

                    debugger
                    source.eventRecord.resourceId = source.resourceRecord.id;
                    let eventRecord = source.eventRecord;
                    let resourceRecord = source.resourceRecord;
                    if (isExists(source.eventRecord.parentRelease)) {
                        eventRecord = props.scheduler.current.schedulerInstance.eventStore.getById(source.eventRecord.parentRelease);
                    }
                    if (!eventRecord.isArchive &&
                        resourceRecord.type !== EVENT_TYPE.PROMO) {
                        showEditor(eventRecord, resourceRecord);
                    }

                    return false;
                },
                beforeEventDrag: ({eventRecord}) => {
                    console.log('beforeEventDrag')

                    if (!eventRecord.isArchive) {
                        if (eventRecord.type !== EVENT_TYPE.PROMO) {
                            return true
                        }
                    }
                    return false
                },
                beforeEventResize: ({eventRecord}) => {

                    timeInStartDate = eventRecord.data.startDate.getHours();
                    timeInEndDate = eventRecord.data.endDate.getHours();
                    if (!eventRecord.isArchive) {
                        if (eventRecord.type === EVENT_TYPE.EVENT
                            || eventRecord.type === EVENT_TYPE.AB_TEST
                            || eventRecord.type === EVENT_TYPE.ASO
                            || eventRecord.type === EVENT_TYPE.FEATURING
                            || eventRecord.type === EVENT_TYPE.BRANDFORMANCE) {
                            return true
                        }
                    }
                    return false
                },
                timeAxisHeaderClick: (event) => {
                    processTimeAxisHeaderClick(props.scheduler, event);
                },
                eventSelectionChange: ({action, selected, selection}) => {
                    console.log('eventSelectionChange')

                    let currentSelectedEvent = selected[0];
                    props.setSelectedItems(selection)
                    if (selection.length > 1) {
                        if (action === "select") {
                            let firstSelectedEvent = selection[0];
                            if (currentSelectedEvent.data.resourceId !== firstSelectedEvent.data.resourceId) {
                                props.scheduler.current.schedulerInstance.deselectEvent(currentSelectedEvent)
                            }
                        }
                    }
                },
                eventDrop: (event) => {
debugger
                    console.log('eventDrop')
                    console.log(event.context)
                    const originalStart = event.context.origStart;
                    const originalEnd = event.context.origEnd;
                    const originalStartDay = DateHelper.get(originalStart, "d");
                    const originalEndDay = DateHelper.get(originalEnd, "d");
                    const originalDuration = DateHelper.diff(originalStart, originalEnd, 'd');

                    event.eventRecords.forEach((eventRecord) => {
                        console.log(eventRecord)

                        const newStartDay = DateHelper.get(eventRecord.startDate, "d")
                        const newEndDay = DateHelper.get(eventRecord.endDate, "d")


                        const doUseTime = (props) => {
                            return props.eventRecord.type === EVENT_TYPE.EVENT ||
                                props.eventRecord.type === EVENT_TYPE.RELEASE ||
                                props.eventRecord.type === EVENT_TYPE.MATCH3 ||
                                props.eventRecord.type === EVENT_TYPE.ASO ||
                                props.eventRecord.type === EVENT_TYPE.AB_TEST ||
                                props.eventRecord.type === EVENT_TYPE.AB_TEST_COMMENT;
                        }

                        eventRecord.useTimeInStartDate = doUseTime({eventRecord: eventRecord})
                        eventRecord.useTimeInEndDate = doUseTime({eventRecord: eventRecord})

                        if(eventRecord.type === EVENT_TYPE.EVENT) {
                            eventRecord.name = replaceOrAppendDate(eventRecord)
                        }

                        if (originalStart.getHours() > originalEnd.getHours()) {
                            eventRecord.endDate = DateHelper.add(eventRecord.startDate, eventRecord.duration, 'd');
                        }

                        if (originalStartDay !== newStartDay && originalEndDay != newEndDay) {
                            eventRecord.startDate = copyHours(eventRecord.startDate, originalStart)
                            eventRecord.endDate = DateHelper.add(eventRecord.startDate, originalDuration, 'd')
                            eventRecord.endDate = copyHours(eventRecord.endDate, originalEnd)
                            //DateHelper.copyTimeValues(eventRecord.startDate, originalStart)
                            //DateHelper.copyTimeValues(eventRecord.endDate, originalEnd)
                        }
                        eventRecord.duration = DateHelper.diff(eventRecord.startDate, eventRecord.endDate, 'd');

                        if (!eventRecord.isCopy) {
                            setFeaturingType(eventRecord)
                            sendUpdateEvent(eventRecord, SELECTED_GAME.CM.toLowerCase());
                        }
                        // setStylesForNewEvents(eventRecord, )
                    })

                    props.scheduler.current.schedulerInstance.refreshRows();
                },
                beforeCopy: (event) => {

                    clearCopiedFieldForEvents(event.source.eventStore)
                    let disabledEvent = event.records.find(record => [EVENT_TYPE.ASO, EVENT_TYPE.OFFER, EVENT_TYPE.PROMO].includes(record.type));
                    if (isExists(disabledEvent)) {
                        return false;
                    } else {
                        event.records.forEach(record => {
                            record.isCopied = true
                            record.originalID = record.id
                            record.copiedTime = DateHelper.get(record.startDate, 'h')
                        })
                        return true
                    }
                },
                beforePaste: (event) => {
                    if (event.resourceRecord.id === event.records[0].resourceId) {
                        return isExists(event.date)
                    } else {
                        return false
                    }
                },
                eventResizeEnd: (event) => {
                    console.log('eventResizeEnd')
                    console.log(event)
                    let eventRecord = event.eventRecord

                    const doUseTime = (props) => {
                        return props.eventRecord.type === EVENT_TYPE.EVENT ||
                            props.eventRecord.type === EVENT_TYPE.RELEASE ||
                            props.eventRecord.type === EVENT_TYPE.MATCH3 ||
                            props.eventRecord.type === EVENT_TYPE.ASO ||
                            props.eventRecord.type === EVENT_TYPE.AB_TEST ||
                            props.eventRecord.type === EVENT_TYPE.AB_TEST_COMMENT;
                    }

                    const withTime = doUseTime({eventRecord: eventRecord})
                    eventRecord.useTimeInStartDate = withTime
                    eventRecord.useTimeInEndDate = withTime

                    if(eventRecord.type === EVENT_TYPE.EVENT) {
                        eventRecord.name = replaceOrAppendDate(eventRecord)
                    }

                    if(withTime) {
                        eventRecord.endDate = copyHours(eventRecord.endDate, eventRecord.data.endTime)
                        console.log('eventRecord.endDate')
                        console.log(eventRecord.endDate)
                    } else {
                        DateHelper.copyTimeValues(eventRecord.endDate, eventRecord.startDate);
                    }

                    eventRecord.duration = DateHelper.diff(eventRecord.startDate, eventRecord.endDate, 'd');
                    setHoursForEventDates(eventRecord, timeInStartDate,timeInEndDate);
                    console.log(eventRecord.endDate)

                    setFeaturingType(eventRecord);
                    sendUpdateEvent(eventRecord, SELECTED_GAME.CM.toLowerCase());
                    hideEventTimeRange(props.scheduler);
                },
                eventClick: ({event, eventRecord}) => {


                    setEventTimeRange(props.scheduler, eventRecord);
                    return eventClick(event, eventRecord)
                },
                scheduleClick: () => {
                    hideEventTimeRange(props.scheduler);
                }
            },

            scheduleMenuFeature: {
                processItems({resourceRecord, items}) {
                    if (isExists(items.addEvent)) {
                        items.addEvent.disabled = false
                    }

                    if (resourceRecord.type === EVENT_TYPE.PROMO) {
                        items.addEvent.hidden = true;
                        if (items.pasteEvent !== undefined) {
                            items.pasteEvent.hidden = true;
                        }

                    } else if (resourceRecord.type === EVENT_TYPE.RELEASE) {
                        if (items.pasteEvent !== undefined) {
                            items.pasteEvent.hidden = true;
                        }
                    }
                }
            },
            eventDragSelectFeature: true,
            eventDragCreateFeature: false
        }

    }

    const processBeforeEventDelete = ({eventRecords}) => {
        debugger
        const deletedEvents = eventRecords.map(
            (eventRecord) => isExists(eventRecord.parentRelease)
                ? props.eventStore.getById(eventRecord.parentRelease)
                : eventRecord
        )
        setDeleteRecords(deletedEvents);
        setExistsInAsana(!!eventRecords[0].data.link);
        props.showPopup("deleteEvents");
        return false;
    }

    const showEditor = useCallback((eventRecord, resourceRecord) => {
        eventRecord.type = resourceRecord.data.type;
        let popupData
        if (resourceRecord.data.type === EVENT_TYPE.EVENT || resourceRecord.data.type === EVENT_TYPE.MATCH3 ||
            resourceRecord.data.type === EVENT_TYPE.AB_TEST) {
            popupData = {hasTime: true, type: resourceRecord.data.type, hasEndDate: true}
        } else if ([EVENT_TYPE.HOLIDAY, EVENT_TYPE.FEATURING, EVENT_TYPE.IMPORTANT_DATES].includes(resourceRecord.data.type)) {
            popupData = {hasTime: false, type: "default", hasEndDate: true}
        } else if ([EVENT_TYPE.COMMUNITY].includes(resourceRecord.data.type)) {
            popupData = {hasTime: false, type: resourceRecord.data.type, hasEndDate: false}
        } else if ([EVENT_TYPE.ASO].includes(resourceRecord.data.type)) {
            popupData = {hasTime: false, type: resourceRecord.data.type, hasEndDate: true}
        }
        else if (eventRecord.hasGeneratedId && resourceRecord.data.type === EVENT_TYPE.RELEASE) {
            popupData = "releaseCreate"
        } else if (!eventRecord.hasGeneratedId && resourceRecord.data.type === EVENT_TYPE.RELEASE) {
            popupData = "releaseEdit"
        } else {
            popupData = {hasTime: true, type: "default", hasEndDate: true}
        }
        eventRecord.status = eventRecord.hasGeneratedId ? "create" : "update";
        props.setResourceRecord(resourceRecord);
        props.setEventRecord(eventRecord);
        props.showPopup(popupData);
    }, []);

    return (
        <>{isExists(user.email) &&
            <>
                <BryntumScheduler
                    ref={props.scheduler}
                    {...props.getBryntumConfigs(getEditorConfigs)}

                />
                <div>

                    {(props.popupShown && typeof props.popupShown === "object") &&
                        <Popup
                            labelsConfig={labelsConfig}
                            eventRecord={props.eventRecord}
                            eventStore={props.eventStore}
                            resourceRecord={props.resourceRecord}
                            onSave={props.handleClickSave}
                            onClose={props.hideEditor}
                            popupData={props.popupShown}
                            game={SELECTED_GAME.CM}
                            scheduler={props.scheduler.current.schedulerInstance}
                        />
                    }
                    {props.popupShown === "releaseCreate" && (
                        <ReleaseCreatePopup
                            closePopup={props.hideEditor}
                            eventRecord={props.eventRecord}
                            eventStore={props.eventStore}
                            dependencyStore={props.dependencyStore}
                            resourceRecord={props.resourceRecord}
                            onSave={props.handleClickSave}
                            onClose={props.hideEditor}
                            onSaveChild={props.handleClickSaveChildRelease}
                            releaseNames={RELEASES}
                            game={SELECTED_GAME.CM}
                        />
                    )}
                    {props.popupShown === "releaseEdit" && (
                        <ReleaseEditPopup
                            closePopup={props.hideEditor}
                            eventRecord={props.eventRecord}
                            eventStore={props.eventStore}
                            dependencyStore={props.dependencyStore}
                            resourceRecord={props.resourceRecord}
                            onSave={props.handleClickSave}
                            onClose={props.hideEditor}
                            onSaveChild={props.handleClickSaveChildRelease}
                            game={SELECTED_GAME.CM}
                        />
                    )}
                    {props.popupShown === "noteEdit" && (
                        <EditNotePopup
                            eventRecord={props.eventRecord}
                            eventStore={props.eventStore}
                            onClose={props.hideEditor}
                            game={SELECTED_GAME.CM}
                        />
                    )}
                    {props.popupShown === "deleteEvents" && (
                        <DeletePopup
                            deleteRecords={deleteRecords}
                            eventStore={props.eventStore}
                            selectedGame={SELECTED_GAME.CM}
                            // onSave={props.handleClickSave}
                            existsInAsana={existsInAsana}
                            onClose={props.hideEditor}
                            scheduler={props.scheduler}
                        />
                    )}
                </div>
            </>
        }
        </>);
};

export default manageCalendar(CMCalendar, SELECTED_GAME.CM)
